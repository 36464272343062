<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/analytics_36.svg"
            headerText="Setup Your Analytics Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="(step) => (isDetails ? updateCollect(step) : createCollect(step))"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-input-positionBased>
                <AttributionSlider v-model="$v.collectForm.positionBased.$model" />
            </template>

            <template v-slot:step-3-input-bigquery>
                <b-row>
                    <b-col md="2" />
                    <b-col>
                        <b-row>
                            <b-col md="8">
                                <span class="small-info-text">
                                    Make sure Your dedicated Service Account has access to the project You store Your
                                    Analytics 4 tables in.
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <ChooseBQTable
                    v-model="$v.bigQueryForm.$model"
                    ref="chooseBQTable"
                    depth="dataset"
                    dataset-filter="analytics_"
                />
            </template>

            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedback"
                            :state="feedback.state"
                            :valid="feedback.valid"
                            :invalid="feedback.invalid"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedback"
                            :state="feedback.state"
                            :valid="feedback.valid"
                            :invalid="feedback.invalid"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import AttributionSlider from '@/components/AttributionSlider.vue'
import ChooseBQTable from '@/components/ChooseBQTable.vue'
import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
        WitInputGroup,
        ChooseBQTable,
        AttributionSlider,
    },
    data() {
        return {
            collectForm: {
                name: null,
                campaignTimeout: 90,
                connectedAds: [],
                timeDecay: 7,
                positionBased: [0, 40, 60, 100],
            },
            bigQueryForm: {
                project: null,
                dataset: null,
                table: 'events_YYYYMMDD',
            },
            analyticsForm: {
                tokenId: null,
                account: null,
                property: null,
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            accounts: [],
            webProperties: [],
            currentCollectForm: {},
            currentBigQueryForm: {},
            currentAnalyticsForm: {},
            optionsShown: false,
            feedback: null,
            apiScope: ['https://www.googleapis.com/auth/analytics.readonly'],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            campaignTimeout: {required},
            connectedAds: {},
            timeDecay: {},
            positionBased: {},
        },
        analyticsForm: {
            tokenId: {required},
            account: {required},
            property: {required},
        },
        bigQueryForm: {
            project: {required},
            dataset: {required},
            table: {},
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    async created() {
        await this.$store.dispatch('pipeline/getPipelines', this.activeProject.id)

        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            pipelines: 'pipeline/pipelines',
            loading: 'loading/state',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        formId() {
            return this.isDetails ? 'analytics-create-form' : 'analytics-details-form'
        },
        adsCollects() {
            return this.pipelines
                .filter((p) => p.subtype.includes('Google Ads'))
                .map((el) => ({
                    id: el.id,
                    label: el.name,
                }))
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'campaignTimeout',
                            value: this.$v.collectForm.campaignTimeout.$model,
                            model: this.$v.collectForm.campaignTimeout,
                            type: 'number',
                            inputLabel: 'Campaign timeout (days)',
                            placeholder: 'Enter your campaign timeout',
                            invalid: 'This field is required',
                            valid: 'Campaign timeout is valid',
                        },
                        {
                            name: 'connected-ads',
                            value: this.$v.collectForm.connectedAds.$model,
                            model: this.$v.collectForm.connectedAds,
                            options: this.adsCollects,
                            type: 'treeselect',
                            label: 'name',
                            subLabel: 'type',
                            inputLabel: 'Connect Google Ads accounts',
                            placeholder: 'Select Ads Collect',
                            invalid: 'This field is required',
                            valid: 'Ads Collect is valid',
                            isAdvanced: true,
                        },
                        {
                            name: 'timeDecay',
                            value: this.$v.collectForm.timeDecay.$model,
                            model: this.$v.collectForm.timeDecay,
                            type: 'number',
                            inputLabel: 'Attribution - Time decay (days)',
                            placeholder: 'Enter your time decay',
                            invalid: 'This field is required',
                            valid: 'Time decay is valid',
                            isAdvanced: true,
                        },
                        {
                            name: 'positionBased',
                            isAdvanced: true,
                        },
                    ],
                },
                {
                    name: 'Analytics Settings',
                    invalid: this.$v.analyticsForm.$invalid || this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'token',
                            value: this.$v.analyticsForm.tokenId.$model,
                            model: this.$v.analyticsForm.tokenId,
                            type: 'google-token',
                            scope: this.apiScope,
                            requestedScopes: this.apiScope,
                            inputLabel: 'Select Google token',
                            onInput: this.onTokenInput,
                        },
                        {
                            name: 'account',
                            value: this.$v.analyticsForm.account.$model,
                            model: this.$v.analyticsForm.account,
                            type: 'treeselect',
                            inputLabel: 'Account',
                            options: this.accounts,
                            valueFormat: 'object',
                            multiple: false,
                            placeholder: this.accounts.length
                                ? 'Select accounts'
                                : 'Authorize with Analytics or select a token first',
                            invalid: 'This field is required',
                            valid: 'Account is valid',
                            disabled: !this.accounts.length,
                            onInput: this.onAccountSelect,
                        },
                        {
                            name: 'property',
                            value: this.$v.analyticsForm.property.$model,
                            model: this.$v.analyticsForm.property,
                            type: 'treeselect',
                            inputLabel: 'Web property',
                            options: this.webProperties,
                            valueFormat: 'object',
                            multiple: false,
                            placeholder: this.webProperties.length
                                ? 'Select web property'
                                : 'Authorize with Analytics or select a token first',
                            invalid: 'This field is required',
                            valid: 'Property is valid',
                            disabled: !this.webProperties.length,
                        },
                    ],
                },
                {
                    name: 'BigQuery Settings',
                    inputs: [
                        {
                            name: 'bigquery',
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid:
                              this.$v.collectForm.$invalid ||
                              this.$v.bigQueryForm.$invalid ||
                              this.$v.analyticsForm.$invalid ||
                              this.$v.workflowForm.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            const bigQueryFormDirty = filterDirty(this.bigQueryForm, this.currentBigQueryForm)
            const analyticsFormDirty = filterDirty(this.analyticsForm, this.currentAnalyticsForm)

            return Object.keys({...collectFormDirty, ...bigQueryFormDirty, ...analyticsFormDirty}).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/analytics4bq/${this.$route.params.id}`
            )
            this.collect = response.data.data

            this.collectForm = {
                name: this.collect.name,
                campaignTimeout: this.collect.campaignTimeout,
                timeDecay: this.collect.timeDecay,
                positionBased: this.collect.positionBased,
                connectedAds: this.collect.connectedAdsRefs.map(({id}) => id),
            }

            this.bigQueryForm = {
                project: this.collect.project,
                dataset: this.collect.dataset,
                table: this.collect.table,
            }

            this.analyticsForm.tokenId = this.collect.tokenRef ? this.collect.tokenRef.id : null
            await this.onTokenInput()

            this.analyticsForm.account = this.collect.account
            await this.onAccountSelect()

            this.analyticsForm.property = this.collect.property

            this.currentCollectForm = cloneDeep(this.collectForm)
            this.currentAnalyticsForm = cloneDeep(this.analyticsForm)
            this.currentBigQueryForm = cloneDeep(this.bigQueryForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                const bigQueryFormDirty = filterDirty(this.bigQueryForm, this.currentBigQueryForm)
                const analyticsFormDirty = filterDirty(this.analyticsForm, this.currentAnalyticsForm)

                if (this.dirty) {
                    const data = {
                        ...collectFormDirty,
                        ...bigQueryFormDirty,
                        ...analyticsFormDirty,
                        table: 'events_YYYYMMDD',
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/analytics4bq/${this.$route.params.id}`,
                            data
                        )

                        this.feedback = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.currentAnalyticsForm = cloneDeep(this.analyticsForm)
                        this.currentBigQueryForm = cloneDeep(this.bigQueryForm)

                        this.$forceUpdate()
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {
                            state: false,
                            invalid: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedback = {
                    state: null,
                    invalid: '',
                }

                const data = {
                    ...this.collectForm,
                    ...this.analyticsForm,
                    ...this.bigQueryForm,
                    ...this.workflowForm,
                    table: 'events_YYYYMMDD',
                }

                this.$store.commit('loading/PROCESSING', `Creating your collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/analytics4bq`, data)
                    .then((response) => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch((exception) => {
                        this.$errorHandler.report(exception, 'Could not create form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedback = {
                            state: false,
                            invalid: exception.response.data.data,
                        }
                    })
            }
        },

        async onTokenInput() {
            this.$store.commit('loading/PROCESSING', `Fetching Analytics accounts...`)
            try {
                if (this.fetched || !this.isDetails) {
                    this.accounts = []
                    this.webProperties = []
                    this.analyticsForm.account = null
                    this.analyticsForm.property = null
                }

                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/collect/analytics4bq/accounts`,
                    {
                        params: {tokenId: this.analyticsForm.tokenId},
                    }
                )

                this.accounts = response.data.data.map((account) => {
                    return {id: account.name.split('/')[1], label: account.displayName}
                })

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Token error')
            }
        },

        async onAccountSelect() {
            this.$store.commit('loading/PROCESSING', `Fetching Analytics properties...`)
            try {
                if (this.fetched || !this.isDetails) {
                    this.webProperties = []
                    this.analyticsForm.property = null
                }

                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/collect/analytics4bq/properties`,
                    {
                        params: {tokenId: this.analyticsForm.tokenId, accountId: this.analyticsForm.account.id},
                    }
                )

                this.webProperties = response.data.data.map((webProperty) => {
                    return {
                        id: webProperty.name.split('/')[1],
                        label: webProperty.displayName,
                        currency: webProperty.currencyCode,
                        timeZone: webProperty.timeZone,
                    }
                })

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Token error')
            }
        },
    },
}
</script>

<style lang="scss"></style>
