<template>
    <b-form-group label="Attribution - Position based (credits)" label-for="position-based" id="position-based">
        <b-row class="backtime-row">
            <b-col md="8" class="vue-slider-col">
                <vue-slider
                    v-model="positionBasedValues"
                    tooltip="none"
                    :process="positionBasedProcess"
                    v-bind="positionBasedSliderOptions"
                    :min-range="1"
                    @change="onChange"
                >
                    <template v-slot:process="{style, index}">
                        <div class="vue-slider-process" :style="style">
                            <div class="slider-percent">
                                {{ positionBasedValues[index + 1] - positionBasedValues[index] }}%
                            </div>
                        </div>
                        <div class="vue-slider-process" :style="style">
                            <div>
                                <p class="slider-label" v-if="index === 0">First Source/Medium</p>
                                <p class="slider-label" v-if="index === 1">Middle Source/Medium's</p>
                                <p class="slider-label" v-if="index === 2">Last Source/Medium</p>
                            </div>
                        </div>
                    </template>
                    <template v-slot:label="{active, value}">
                        <div :class="['vue-slider-mark-label', 'custom-label', {active}]">
                            {{ value }}
                        </div>
                    </template>
                </vue-slider>
            </b-col>
        </b-row>
    </b-form-group>
</template>

<script>
import VueSlider from 'vue-slider-component'

import 'vue-slider-component/theme/default.css'

export default {
    components: {
        VueSlider,
    },
    data() {
        return {
            positionBasedValues: [0, 40, 60, 100],
            positionBasedProcess: val => [
                [val[0], val[1], {backgroundColor: '#f7d06e'}],
                [val[1], val[2], {backgroundColor: '#f2b929'}],
                [val[2], val[3], {backgroundColor: '#f1b10e'}],
            ],
            positionBasedSliderOptions: {
                min: 0,
                max: 100,
                'dot-options': [
                    {disabled: true, disabledStyle: {visibility: 'hidden'}},
                    {disabled: false},
                    {disabled: false},
                    {disabled: true, disabledStyle: {visibility: 'hidden'}},
                ],
            },
        }
    },
    props: {
        value: {
            required: true,
            type: Array,
        },
    },
    watch: {
        value() {
            this.positionBasedValues = this.value
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.positionBasedValues)
        },
    },
}
</script>

<style lang="scss" scoped>
#position-based {
    margin-top: 25px;
    margin-bottom: 25px;
}

.backtime-row {
    padding-top: 25px;
}
.vue-slider-col {
    padding-top: 3px;
    z-index: 0;
}

.slider-label {
    white-space: nowrap;
    margin-top: -25px;
}

.slider-percent {
    white-space: nowrap;
    margin-top: 10px;
}
</style>
